import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Col, Form, Input, Row, Alert, Select } from 'antd';
import { Link } from 'react-router-dom';
import Product from './Product';
import products from '../products.json';
import AppSteps from './AppSteps';
import { LoadingOutlined } from '@ant-design/icons';
import States from './states.json';
import Countries from './countries.json';

function dpvCodeToText(code) {
    if(code === 'Y')
        return <Alert message="Your address is confirmed" type="success" showIcon />;
    if(code === 'N')
        return <Alert message="Sorry, but we cannot find correct address for your delivery" type="error" showIcon />;
    if(code === 'S' || code === 'D')
        return <Alert message="Your address is confirmed but may be missing secondary info" type="warning" showIcon />;
}

export default function Step6(props) {
    const [ name, setName ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ city, setCity ] = useState('');
    const [ state, setState ] = useState('');
    const [ zipCode, setZipCode ] = useState('');
    const [ country, setCountry ] = useState('US');
    const [ errors, setErrors ] = useState({ name: '', state: '', address: '', city: '', zipCode: '' });
    const [ selectedProduct, setSelectedProduct] = useState('');
    const [ isLoading, setIsLoading] = useState(false);
    const [ submitError, setSubmitError ] = useState(false);

    const [isValidatingUserAddress, setValidatingUserAddressSpinner] = useState(false);
    const [addressValidationStatus, setAddressValidationStatus] = useState(null);

    useEffect(() => {
        if(providedAllDeliveryInformation(city, name, zipCode, state, name, country)) {
            verifyUserAddress(name, address, city, state, zipCode, country, props.match.params.guid);
        }
    }, [city, address, zipCode, state, name, country, props.match.params.guid])

    const validateInput = (input) => {
        if(input.id === 'name') {
            setErrors({...errors, name: isValidName(input.value) ? 'success' : 'error'});
            setName(input.value)
        }

        if(input.id === 'address') {
            setErrors({...errors, address: isValidAddress(input.value) ? 'success' : 'error'});
            setAddress(input.value)
        }

        if(input.id === 'city') {
            setErrors({...errors, city: isValidCity(input.value) ? 'success' : 'error'}); 
            setCity(input.value)
        }

        if(input.id === 'state') {
            setErrors({...errors, state: isValidState(input.value, country) ? 'success' : 'error'}); 
            setState(input.value)
        }

        if(input.id === 'zipCode') {
            setErrors({...errors, zipCode: isValidZipCode(input.value) ? 'success' : 'error'}); 
            setZipCode(input.value)
        }

        if(input.id === 'country') {
            setErrors({
                ...errors, 
                country: isValidCountry(input.value) ? 'success' : 'error',
                state: isValidState(state, input.value) ? 'success' : 'error'
            }); 
            setCountry(input.value)
        }
    }

    const verifyUserAddress = (name, address, city, state, zipCode, country, orderId) => {
        setValidatingUserAddressSpinner(true);

        axios
            .post(`/api/locations/${orderId}`, {
                name,
                address,
                city,
                state,
                zipCode,
                country
            })
            .then(response => response.data)
            .then(status => setAddressValidationStatus(status))
            .finally(_ => setValidatingUserAddressSpinner(false));
    }

    const submit = () => {
        setIsLoading(true);
        setSubmitError(false);
        axios
             .post(`/api/orders/${props.match.params.guid}/6`, {
                name,
                address,
                city,
                state,
                zipCode,
                country
             })
             .then(_ => {
                 props.history.push(`/${props.match.params.guid}/summary`);
             })
             .catch(() => {
                setIsLoading(false);
                setSubmitError(true);
             });
    }

    const noMattchingAddresWasFound = () => {
        return name && city && zipCode && address && state && !isValidatingUserAddress && !addressValidationStatus;
    }

    const providedAllDeliveryInformation = (name, city, zipCode, address, state, country) => {
        return name && city && zipCode && address && state && country;
    }

    const isValidName = (name) => name && name.length > 0;
    const isValidAddress = (address) => address && address.length > 0;
    const isValidCity = (city) => city && city.length > 0;
    const isValidState = (state, country) => (state && state.length > 0) || country !== 'US';
    const isValidZipCode = (zipCode) => zipCode && zipCode.length > 0;
    const isValidCountry = (country) => country && country.length > 0;

    const disabled = errors.name === 'success'
                    && errors.city === 'success'
                    && errors.state === 'success'
                    && errors.zipCode === 'success'
                    && errors.address === 'success'
                        ? {} 
                        : { disabled: 'disabled' };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        axios
            .get(`/api/orders/${props.match.params.guid}`)
            .then(response => response.data)
            .then(data => {
                if(!data) 
                    return;

                setName(data.first_name);
                setAddress(data.address_street);           
                setCity(data.address_city);
                setState(data.address_state);
                setZipCode(data.address_zipcode);
                setSelectedProduct(data.selected_product);
                setCountry(data.country || 'US');

                setErrors({
                    name: !data.first_name ? '' : (isValidName(data.first_name) ? 'success' : 'error'),
                    address: !data.address_street ? '' : (isValidAddress(data.address_street) ? 'success' : 'error'),
                    city: !data.address_city ? '' : (isValidCity(data.address_city) ? 'success' : 'error'),
                    state: !data.address_state ? '' : (isValidState(data.address_state, data.country)  ? 'success' : 'error'),
                    zipCode: !data.address_zipcode ? '' : (isValidZipCode(data.address_zipcode) ? 'success' : 'error')});
            })
    }, [props.match.params.guid]);

    var selectedProductDiv = '';
    
    if(selectedProduct) {
        selectedProductDiv = 
            <>
                <Product sku={selectedProduct} isSelected={true} />
                <h2>{products[selectedProduct].title}</h2>
                <p>{products[selectedProduct].description}</p>
            </>
    }

    return (
        <>
            <AppSteps step={4} onClickHelp={props.onClickHelp}>
                <Row gutter={25}>
                    <Col md={9} span={24} className="selected-product">
                        <h2>You have selected</h2>
                        {selectedProductDiv}
                        <Link to={`/${props.match.params.guid}/5`}>Change my free product selection</Link>
                    </Col>
                    <Col md={14} span={24}>
                        <div className="Step-container">
                            <h1>Confirm your address</h1>
                            <Form layout="vertical">
                                <Form.Item label="Name" hasFeedback validateStatus={errors.name}>
                                    <Input size="large" id="name" value={name} onChange={e => validateInput(e.target)}/>
                                </Form.Item>

                                <Form.Item label="Address" hasFeedback validateStatus={errors.address}>
                                    <Input size="large" id="address" value={address} onChange={e => validateInput(e.target)}/>
                                </Form.Item>

                                <Form.Item label="City" hasFeedback validateStatus={errors.city}>
                                    <Input size="large" id="city" value={city} onChange={e => validateInput(e.target)}/>
                                </Form.Item>

                                <Form.Item label="State" hasFeedback validateStatus={errors.state}>
                                    {country === 'US' && <Select
                                        id="state"
                                        size="large"
                                        showSearch
                                        value={state}
                                        onChange={value => validateInput({id: "state", value})}
                                        optionFilterProp="children">
                                        {States.map(s => <Select.Option value={s.abbreviation}>{s.name}</Select.Option>)}
                                    </Select>}

                                    {country !== 'US' && <Input size="large" id="state" value={state} onChange={e => validateInput(e.target)} />}
                                </Form.Item>

                                <Form.Item label="Zip" hasFeedback validateStatus={errors.zipCode}>
                                    <Input size="large" id="zipCode" value={zipCode} onChange={e => validateInput(e.target)}/>
                                </Form.Item>

                                <Form.Item label="Country">
                                    <Select
                                        id="country"
                                        size="large"
                                        aria-autocomplete="none"
                                        showSearch
                                        value={country}
                                        onChange={value => validateInput({id: "country", value})}
                                        optionFilterProp="children">
                                        {Countries.map(s => <Select.Option value={s.abbreviation}>{s.name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Form>

                            {isValidatingUserAddress && 
                                <h2 className="validating-address">
                                    <LoadingOutlined/> We are checking your delivery address
                                </h2>
                            }

                            {!isValidatingUserAddress && addressValidationStatus && dpvCodeToText(addressValidationStatus.dpv_match_code)}

                            {submitError && <Alert message="Sorry, but we cannot submit your request. Please check if your address is correct." type="error" showIcon />}
                            {noMattchingAddresWasFound() && <Alert message="Sorry, but we cannot find correct address for your delivery." type="error" showIcon />}

                            <Button 
                                type="primary"
                                size="large"
                                loading={isLoading}
                                onClick={submit}
                                style={{ padding: "0px 50px", marginTop: 25 }}
                                {...disabled}
                            >
                                FINISH
                            </Button>
                        </div>
                    </Col>
                </Row>
            </AppSteps>
        </>
    );
}