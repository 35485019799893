import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {
    Button,
    Col,
    Row,
} from 'antd';
import AppSteps from './AppSteps';
import Product from './Product';

export default function Step5(props) {
    const [ selectedGift, setSelectedGift ] = useState('');
    const [ hideProducts, setHideProducts ] = useState(null);

    const disabled = selectedGift === '';

    useEffect(() => {
        window.scrollTo(0, 0);

        //Calls the api to get the in stock products
        axios
            .get(`/api/stock`)
            .then(response => response.data)
            .then(
                response => {
                    setHideProducts(JSON.parse(response.hideProducts));
                },
                () => {
                    
                });
    }, [])

    const submit = () => {
        axios
            .post(`/api/orders/${props.match.params.guid}/5`, {
                selectedGift
            })
            .then(res => {
                props.history.push(`/${props.match.params.guid}/6`);
            });
    }

    if(hideProducts === null) {
        return(null)
    } else {
        return (
            <>
                <AppSteps step={3} onClickHelp={props.onClickHelp}>
                    <h1>Select your free product</h1>
                    <Row>
                        {(!hideProducts['RW-TN78-ICEE'] &&
                            <Col md={12} span={24}>
                                <Product 
                                    sku="RW-TN78-ICEE"
                                    title="Collagen Peptides Vanilla Powder with Biotin, Vitamin C and Hyaluronic Acid"
                                    onSelect={() => setSelectedGift('RW-TN78-ICEE')}
                                    isSelected={selectedGift === '' || selectedGift === 'RW-TN78-ICEE'}
                                />
                            </Col>
                        )}
                        {(!hideProducts['SC-9727-M4I2'] &&
                            <Col md={12} span={24}>
                                <Product 
                                    sku="SC-9727-M4I2"
                                    title="Omega 3 Fish Oil"
                                    onSelect={() => setSelectedGift('SC-9727-M4I2')}
                                    isSelected={selectedGift === '' || selectedGift === 'SC-9727-M4I2'}
                                />
                            </Col>
                        )}
                        {(!hideProducts['8S-744P-IMLQ'] &&
                            <Col md={12} span={24}>
                                <Product 
                                    sku="8S-744P-IMLQ"
                                    title="Digestive Enzymes"
                                    onSelect={() => setSelectedGift('8S-744P-IMLQ')}
                                    isSelected={selectedGift === '' || selectedGift === '8S-744P-IMLQ'}
                                />
                            </Col>
                        )}
                        {(!hideProducts['JN-45AT-38WB'] &&
                            <Col md={12} span={24}>
                                <Product 
                                    sku="JN-45AT-38WB"
                                    title="Chocolate Collagen Peptides Powder with Hyaluronic Acid, Vitamin C &amp; Biotin"
                                    onSelect={() => setSelectedGift('JN-45AT-38WB')}
                                    isSelected={selectedGift === '' || selectedGift === 'JN-45AT-38WB'}
                                />
                            </Col>
                        )}
                        {(!hideProducts['ZW-LENN-MEFY'] &&
                            <Col md={12} span={24}>
                                <Product 
                                    sku="ZW-LENN-MEFY"
                                    title="Best Prebiotics and Probiotics, 15 Billion CFU, 15 Strains. Digestive, Immune System &amp; Weight Loss Support."
                                    onSelect={() => setSelectedGift('ZW-LENN-MEFY')}
                                    isSelected={selectedGift === '' || selectedGift === 'ZW-LENN-MEFY'}
                                />
                            </Col>
                        )}
                        {(!hideProducts['YG-UT8Z-G5RM'] &&
                            <Col md={12} span={24}>
                                <Product 
                                    sku="YG-UT8Z-G5RM"
                                    title="Pure Ashwagandha Capsules with Rhodiola &amp; Ginseng Extract. Natural Stress Relief.  Mood Enhancer for Women &amp; Men. Sleep"
                                    onSelect={() => setSelectedGift('YG-UT8Z-G5RM')}
                                    isSelected={selectedGift === '' || selectedGift === 'YG-UT8Z-G5RM'}
                                />
                            </Col>
                        )}
                        {(!hideProducts['UV-2TP0-7IUL'] &&
                            <Col md={12} span={24}>
                                <Product 
                                    sku="UV-2TP0-7IUL"
                                    title="Organic Ksm-66 Ashwagandha Capsules. High Potency 5% Withanolides, Natural Sleep Support, Clinical Effects"
                                    onSelect={() => setSelectedGift('UV-2TP0-7IUL')}
                                    isSelected={selectedGift === '' || selectedGift === 'UV-2TP0-7IUL'}
                                />
                            </Col>
                        )}
                    </Row>
                    <Button 
                        disabled={disabled} 
                        style={{ padding: "0px 50px", margin: "0px auto", display: "block", marginTop: 25 }} 
                        type="primary" 
                        size="large" 
                        onClick={submit}
                    >
                        NEXT
                    </Button>
                </AppSteps>
            </>
        )
    }
}
