import React from 'react';
import { Form, Input, Button, Spin, Table, Tooltip, message, Row, Col, Modal, Checkbox } from 'antd';
import {
    MailOutlined,
    FileTextOutlined,
    PhoneOutlined
} from '@ant-design/icons';
import axios from 'axios';
import products from '../products.json';

const columns = [
    {
        title: 'Contact details',
        key: 'contact',
        render: (_, row) => <div>
            {row.first_name} {row.last_name}<br />
            <div style={{ whiteSpace: 'nowrap' }}><MailOutlined /> &nbsp; {row.email}</div>
            <div style={{ whiteSpace: 'nowrap' }}><PhoneOutlined /> &nbsp; {row.phone}</div>
        </div>
    },
    {
        title: 'Order # and review',
        key: 'order',
        render: (_, row) => (<>
            {row.order_no} &nbsp;
            <Tooltip placement="topLeft" title={(row.rate ? (row.rate + ' stars ') : '') + (row.review||'')}>
                <FileTextOutlined />
            </Tooltip>
        </>
        )
    },
    {
        title: 'Shipping address',
        key: 'shipping_address',
        render: (_, row) => `${row.address_street || ''}, ${row.address_city || ''}, ${row.address_state || ''}, ${row.address_zipcode || ''}`
    },
    {
        title: 'Selected product',
        dataIndex: 'selected_product',
        render: (selected_product) => `${selected_product || ''} ${(products[selected_product] || {}).title || ''}`
    },
    {
        title: 'Date added',
        dataIndex: 'created_at',
        key: 'created_at'
    },
    {
        title: 'Amazon link clicked',
        dataIndex: 'amazon_clicked_at'
    }
];
export default class Manager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            orders: null,
            loggedIn: false,
            facebookUrl: '',
            skipReview: false,
            settingsVisible: false,
            hideProducts: {
                'RW-TN78-ICEE': false,
                'SC-9727-M4I2': false,
                '8S-744P-IMLQ': false,
                'JN-45AT-38WB': false,
                'ZW-LENN-MEFY': false,
                'YG-UT8Z-G5RM': false,
                'UV-2TP0-7IUL': false
            }
        };
    }

    onLogOnSubmit = (auth) => {
        this.setState({ loading: true });
        
        axios
            .get(`/api/admin/orders`, { auth })
            .then(response => response.data)
            .then(
                orders => {
                    this.setState({ orders, loading: false, loggedIn: true});
                },
                () => {
                    this.setState({ loading: false });
                    message.error('Login failed');
                });
    }

    saveFacebookUrl = () => {
        this.setState({ loading: true });
        
        axios
            .post(`/api/admin/settings`, 
                { 
                    facebookUrl: this.state.facebookUrl,
                    skipReview: this.state.skipReview,
                    hideProducts: JSON.stringify(this.state.hideProducts)
                }, { withCredentials: true })
            .then(response => response.data)
            .then(
                () => {
                    this.setState({ loading: false, settingsVisible: false });
                },
                () => {
                    this.setState({ loading: false });
                    message.error('Error occured.');
                });
    }

    openSettings = () => {
        this.setState({ loading: true });

        axios
            .get(`/api/admin/settings`, { withCredentials: true })
            .then(response => response.data)
            .then(
                response => {
                    this.setState({ loading: false, settingsVisible: true, facebookUrl: response.facebookUrl, skipReview: response.skipReview, hideProducts: JSON.parse(response.hideProducts) });
                },
                () => {
                    this.setState({ loading: false });
                    message.error('Error occured.');
                });
    }

    closeSettings = () => {
        this.setState({ 
            settingsVisible: false,
        })
    }

    render() {
        const { 
            orders, 
            settingsVisible, 
            loading,
            loggedIn,
            facebookUrl,
            skipReview,
            hideProducts
        } = this.state;

        const content = loggedIn ?
            <>
                <Row>
                    <Col>
                        <Button type="link" onClick={this.openSettings}>Settings</Button> 
                        <Modal
                            title="Settings"
                            visible={settingsVisible}
                            onOk={this.saveFacebookUrl}
                            onCancel={this.closeSettings}
                        >
                            <Form layout="vertical">
                                <Form.Item label="Join The Conversation on FB link">
                                    <input style={{width: '450px'}} value={facebookUrl} onChange={e => this.setState({facebookUrl: e.target.value })} />
                                </Form.Item>
                                <Form.Item>
                                    <Checkbox checked={skipReview}
                                        onChange={e => this.setState({skipReview: e.target.checked })}>
                                        Skip Amazon review step
                                        </Checkbox>
                                </Form.Item>
                                <Form.Item label="Hide Products Out Of Stock">
                                    <div className='settings-hide'>
                                        <div>
                                            <img src="/resources/product-RW-TN78-ICEE.jpg" alt='product'/>
                                            <Checkbox checked={hideProducts['RW-TN78-ICEE']}
                                            onChange={e => this.setState({hideProducts: {...this.state.hideProducts, 'RW-TN78-ICEE': e.target.checked}})}>
                                                <b>Hide</b> Collagen Peptides Vanilla Powder with Biotin, Vitamin C and Hyaluronic Acid
                                            </Checkbox>
                                        </div>
                                        <div style={{'marginTop': 20, 'marginLeft': -7}}>
                                            <img src="/resources/product-SC-9727-M4I2.jpg" alt='product'/>
                                            <Checkbox style={{'marginLeft': 7}} checked={hideProducts['SC-9727-M4I2']}
                                            onChange={e => this.setState({hideProducts: {...this.state.hideProducts, 'SC-9727-M4I2': e.target.checked}})}>
                                                <b>Hide</b> Omega 3 Fish Oil
                                            </Checkbox>
                                        </div>
                                        <div style={{'marginTop': 20, 'marginLeft': -7}}>
                                            <img src="/resources/product-8S-744P-IMLQ.jpg" alt='product'/>
                                            <Checkbox style={{'marginLeft': 7}} checked={hideProducts['8S-744P-IMLQ']}
                                            onChange={e => this.setState({hideProducts: {...this.state.hideProducts, '8S-744P-IMLQ': e.target.checked}})}>
                                                <b>Hide</b> Digestive Enzymes
                                            </Checkbox>
                                        </div>
                                        <div style={{'marginTop': 20 }}>
                                            <img src="/resources/product-JN-45AT-38WB.jpg" alt='product'/>
                                            <Checkbox checked={hideProducts['JN-45AT-38WB']}
                                            onChange={e => this.setState({hideProducts: {...this.state.hideProducts, 'JN-45AT-38WB': e.target.checked}})}>
                                                <b>Hide</b> Chocolate Collagen Peptides Powder with Hyaluronic Acid, Vitamin C &amp; Biotin
                                            </Checkbox>
                                        </div>
                                        <div style={{'marginTop': 20, 'marginLeft': -7}}>
                                            <img src="/resources/product-ZW-LENN-MEFY.jpg" alt='product'/>
                                            <Checkbox style={{'marginLeft': 7}} checked={hideProducts['ZW-LENN-MEFY']}
                                            onChange={e => this.setState({hideProducts: {...this.state.hideProducts, 'ZW-LENN-MEFY': e.target.checked}})}>
                                                <b>Hide</b> Prebiotics and Probiotics
                                            </Checkbox>
                                        </div>
                                        <div style={{'marginTop': 20, 'marginLeft': -7}}>
                                            <img src="/resources/product-YG-UT8Z-G5RM.jpg" alt='product'/>
                                            <Checkbox style={{'marginLeft': 7}} checked={hideProducts['YG-UT8Z-G5RM']}
                                            onChange={e => this.setState({hideProducts: {...this.state.hideProducts, 'YG-UT8Z-G5RM': e.target.checked}})}>
                                                <b>Hide</b> Pure Ashwagandha Capsules with Rhodiola &amp; Ginseng Extract
                                            </Checkbox>
                                        </div>
                                        <div style={{'marginTop': 20, 'marginLeft': -7}}>
                                            <img src="/resources/product-UV-2TP0-7IUL.jpg" alt='product'/>
                                            <Checkbox style={{'marginLeft': 7}} checked={hideProducts['UV-2TP0-7IUL']}
                                            onChange={e => this.setState({hideProducts: {...this.state.hideProducts, 'UV-2TP0-7IUL': e.target.checked}})}>
                                                <b>Hide</b> Organic Ksm-66 Ashwagandha Capsules
                                            </Checkbox>
                                        </div>
                                    </div>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table title={() => 'List of orders'} bordered dataSource={orders} columns={columns} rowKey={'id'} />
                    </Col>
                </Row> 
            </>: '';

        return (
            <>
                <div className="content">
                    <img src="/resources/logo.png" alt="FreeDrFit logo" width="200" />
                </div>
                <div className="Manager-container">
                    <Spin spinning={loading}>
                        {!loggedIn && 
                            <Row>
                                <Col md={8} span={0}/>
                                <Col md={8} span={24}>
                                    
                                        <LogOnScreen onSubmit={this.onLogOnSubmit} />
                                </Col>
                                <Col md={8} span={0}/>
                            </Row>
                        }        
                        {content}    
                    </Spin>        
                </div>
            </>
        )
    }
};

const LogOnScreen = (props) => (
    <Form
        name="basic"
        className="LogInForm"
        initialValues={{ remember: true }}
        onFinish={props.onSubmit}
        layout="vertical"
    >
        <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
            block
        >
            <Input />
        </Form.Item>

        <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
        >
            <Input.Password />
        </Form.Item>

        <Form.Item>
            <Button type="primary" size="large" block htmlType="submit">Log in</Button>
        </Form.Item>
    </Form>
);