import React from 'react'

export default function Footer(props) {
    return (
        <div className="footer" {...props}>
            <div className="content">
                FREEDRFIT. COPYRIGHT 2021 © — ALL RIGHTS RESERVED 
            </div>
        </div>
    )
}
