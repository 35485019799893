import React from 'react'

export default function Product({ sku, title, isSelected, onSelect, hide }) {

    if(hide) {

        return(null)

    } else {
        return (
            <div onClick={onSelect} className="product">
                <img src={`/resources/product-${sku}.jpg`} className={`${isSelected ? "" : "opacity"}`} alt={title}/>
                <span>{title}</span>
            </div>
        )
    }

    
}
