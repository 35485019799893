import React, { useState } from 'react';
import AppSteps from './AppSteps';
import { Button, Input, Form, message } from 'antd';
import axios from 'axios';

export default function Thanks(props) {
    const [ isLoading, setIsLoading] = useState(false);
    const [ phone, setPhone ] = useState("");

    const submit = () => {
        setIsLoading(true);
        axios
             .post(`/api/orders/${props.match.params.guid}/summary`, {
                phone
             })
             .then(_ => {
                setIsLoading(false);
                message.success("Your phone number has been submitted.");
             })
             .catch(() => {
                setIsLoading(false);
             });
    }
    
    return (
        <>
            <AppSteps step={5} onClickHelp={props.onClickHelp}>
                <div className="thanks">
                    <h1>Success! You’ll Receive Your FREE Product<br/> Within 10 Business Days.</h1>
                    <p>You Will Receive an Email With Tracking Number As Soon As It Has Shipped.</p>
                
                    <p>Before You Go! Enter your phone number below so that we notify you (via SMS) of other FREE products from our Amazon store.</p>
                    
                    <Form layout="vertical" className="order">
                        <Form.Item style={{ marginBottom: "10px" }}>
                            <Input 
                                size="large" 
                                placeholder="Your phone number" 
                                value={phone} 
                                onChange={e => setPhone(e.target.value)}
                            />
                        </Form.Item>
                        <Button 
                            type="primary" 
                            size="large" 
                            onClick={submit}
                            disabled={phone === ""}
                            block
                            loading={isLoading}
                        >
                            Submit
                        </Button>
                    </Form>
                    <div className="progressbar">100%</div>
                </div>
            </AppSteps>
        </>
    )
}
