import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Input, Form, Button, Collapse, Rate, Avatar, Alert } from 'antd';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import Footer from './Footer';
import MaskedInput from 'antd-mask-input'

const { Panel } = Collapse;

const Homepage = (props) => {
    if (!props.match.params.guid) {
        props.history.push(`/${uuid()}`);
    }

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState({ name: '', email: '', phone: '' });
    const [smsCode, setSmsCode] = useState('');
    const [smsCodeSent, setSmsCodeSent] = useState(false);
    const [smsCodeError, setSmsCodeError] = useState('');
    const [phoneSubmitError, setPhoneSubmitError] = useState(false);

    const faq = useRef(null)

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({
            behavior: "smooth",
            block: 'start'
        })
    }

    const validateInput = (input) => {
        if (input.id === 'name') {
            setErrors({ ...errors, name: isValidName(input.value) ? 'success' : 'error' });
            setName(input.value)

        }
        if (input.id === 'email') {
            setErrors({ ...errors, email: isValidEmail(input.value) ? 'success' : 'error' });
            setEmail(input.value)
        }
        if (input.id === 'phone') {
            setErrors({ ...errors, phone: isValidPhone(input.value) ? 'success' : 'error' });
            setPhone(input.value)
        }
    }

    const submit = () => {
        setPhoneSubmitError(false);
        axios
            .post(`/api/orders/${props.match.params.guid}/1`, {
                firstName: name,
                email,
                phone
            })
            .then(_ => {
                setSmsCodeSent(true);
            })
            .catch(err => {
                if (err.response.status === 400) {
                    setPhoneSubmitError(true);
                }
            });
    }

    const submitSmsCode = () => {
        axios
            .post(`/api/orders/${props.match.params.guid}/sms`, {
                smsCode
            })
            .then(_ => {
                props.history.push(`/${props.match.params.guid}/2`);
            })
            .catch(_ => setSmsCodeError('error'));
    }

    const isValidName = (firstName) => firstName && firstName.length > 0;
    const isValidEmail = (email) => /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm.test(email);
    const isValidPhone = (phone) => phone && phone.length > 0;

    let disabled = errors.name === 'success' && errors.email === 'success' && errors.phone === 'success'
        ? {}
        : { disabled: 'disabled' };

    useEffect(() => {
        if (props.location.search.indexOf("showFAQ") !== -1) {
            setTimeout(() => {
                window.location.replace(`/#/${props.match.params.guid}`)
                scrollToRef(faq)
            }, 150)
        }
    }, [props.location.search, props.match.params.guid])

    useEffect(() => {
        window.scrollTo(0, 0);

        if (!props.match.params.guid)
            return;

        axios
            .get(`/api/orders/${props.match.params.guid}`)
            .then(response => response.data)
            .then(data => {
                if (!data || !data.id)
                    return;

                setName(data.first_name);
                setEmail(data.email);
                setPhone(data.phone);

                setErrors({
                    name: isValidName(data.first_name) ? 'success' : 'error',
                    email: isValidEmail(data.email) ? 'success' : 'error',
                    phone: isValidPhone(data.phone) ? 'success' : 'error'
                });
            })
    }, [props.match.params.guid]);

    return (
        <>
            <header className="App-header">
                <div className="content">
                    <img src="/resources/logo.png" alt="FreeDrFit logo" />
                    <Button type="link" onClick={props.onClickHelp}>Help</Button>
                </div>
            </header>
            <div className="Homepage-hero">
                <div className="cover_bg">
                    <div className="content">
                        <Row>
                            <Col md={12}>
                                <div className="Homepage-registrationForm">
                                    {smsCodeSent ?
                                        <Form layout="vertical">
                                            <h3>Confirm your phone number</h3>
                                            <span>Submit SMS code you received on your phone.</span>
                                            <Form.Item hasFeedback validateStatus={smsCodeError}>
                                                <Input placeholder="SMS Code" id="smsCode" size="large" value={smsCode} onChange={e => setSmsCode(e.target.value)} />
                                            </Form.Item>
                                            <Button type="primary" size="large" block onClick={submitSmsCode} disabled={smsCode.length !== 6}>Next</Button>
                                        </Form>
                                        : <Form layout="vertical">
                                            <h3>Share your experience with<br /> us and get a free product!</h3>
                                            <span>Fill this Form to Receive a FREE Product <br />Offer Limited to Existing Customers*</span>
                                            <Form.Item hasFeedback validateStatus={errors.name}>
                                                <Input placeholder="Your name" id="name" size="large" value={name} onChange={e => { validateInput(e.target) }} />
                                            </Form.Item>
                                            <Form.Item hasFeedback validateStatus={errors.email}>
                                                <Input placeholder="E-mail" id="email" size="large" value={email} onChange={e => { validateInput(e.target) }} />
                                            </Form.Item>
                                            <Form.Item hasFeedback validateStatus={errors.phone}>
                                                <MaskedInput mask={"111-111-1111"} placeholder="Phone" id="phone" size="large" value={phone} onChange={e => { validateInput(e.target) }} />
                                            </Form.Item>
                                            <Form.Item style={{ color: '#fff' }}>
                                                By entering your phone number you allow us to send you a text message the day we ship your free product so that you know when to expect it. Also, you can reply via SMS if you have any questions.
                                            </Form.Item>
                                            {phoneSubmitError && <Form.Item>
                                                <Alert
                                                    message="Can't submit your request"
                                                    description="Please check if your name, email and phone number are correct."
                                                    type="error"
                                                    showIcon />
                                            </Form.Item>}
                                            <Button type="primary" size="large" block onClick={submit} {...disabled}>Next</Button>
                                            <p>
                                                We do not share or sell your personal information with anyone.
                                                View {' '}<Link to="/terms-and-conditions" target="_blank">TOS</Link>{' '}
                                                &amp; <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>.
                                            </p>
                                        </Form>
                                    }
                                </div>
                            </Col>
                            <Col md={12} span={24} className="Homepage-list">
                                <h2>Current customers <br />get a <span>FREE Product</span>*</h2>
                                <Row justify="space-between" style={{ width: "100%" }}>
                                    <Col md={7} span={24}>
                                        <h3>NO Credit Card</h3>
                                    </Col>
                                    <Col md={1} span={0}>
                                        -
                                    </Col>
                                    <Col md={7} span={24}>
                                        <h3>NO Shipping</h3>
                                    </Col>
                                    <Col md={1} span={0}>
                                        -
                                    </Col>
                                    <Col md={7} span={24}>
                                        <h3>100% FREE!</h3>
                                    </Col>
                                </Row>
                                <p>
                                    *Conditions Apply: To qualify participants to agree to send us their experience with the
                                    previously purchased product that they have been actively using for at least 15 days or more.
                                    Limited to one free product per household, per order. Applies only to full price purchases.
                                    This offer is not dependent on leaving a review on any marketplace nor the quality or manner
                                    of feedback that you provide. Offer only valid for orders placed within the last 6 months,
                                    through Amazon.com.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="Homepage-content">
                <div className="content">
                    <h2 style={{ marginBottom: 50 }}>Get Your FREE Dr. Matthew® Product <br />in 3 Simple Steps</h2>
                    <Row gutter={{ md: 100, sm: 0 }}>
                        <Col md={8} span={24}>
                            <img src="/resources/get-1.png" alt="Get 1" />
                            <h3>Fill out the form</h3>
                            <p>Please fill out the form above and use the same email you used for the account you purchased our product at.</p>
                        </Col>
                        <Col md={8} span={24}>
                            <img src="/resources/get-2.png" alt="Get 2" />
                            <h3>Confirm your shipping address</h3>
                            <p>Please share your experience with our product to let us know how to continually improve our products and also let other potential future users know your experience.</p>
                        </Col>
                        <Col md={8} span={24}>
                            <img src="/resources/get-3.png" alt="Get 3" />
                            <h3>Receive your FREE product</h3>
                            <p>Upon completion, we will ship you a FREE item and send you a tracking number so you can know when it will arrive. You will receive your FREE item within 10 business days.</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="Homepage-testimontials">
                <div className="content">
                    <h2>What People Are Saying About Us...</h2>
                    <Row gutter={[50, 15]}>
                        <Col md={12} span={24}>
                            <Row gutter={[25, 25]}>
                                <Col md={4} span={24}>
                                    <Avatar size={80} src="/resources/what-1.jpg" />
                                </Col>
                                <Col md={20} span={24}>
                                    <Rate
                                        value={5}
                                        style={{
                                            fontSize: 15,
                                            display: "block"
                                        }}
                                    />
                                    "I have been using collagen for a few years now. I saw Dr. Matthew’s collagen and wanted to give it a try. I can tell a difference in my joints, skin, hair & nails. I am an avid exerciser at 41 years old and love that I can take care of my health from the inside out with this great collagen!"
                                    <h3>ERIN</h3>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} span={24}>
                            <Row gutter={[25, 25]}>
                                <Col md={4} span={24}>
                                    <Avatar size={80} src="/resources/what-2.jpg" />
                                </Col>
                                <Col md={20} span={24}>
                                    <Rate
                                        value={5}
                                        style={{
                                            fontSize: 15,
                                            display: "block"
                                        }}
                                    />
                                    "I like adding collagen to my coffee as an easy way to boost my protein, but can't stand it when the powder has a strong flavor or aftertaste. This powder easily absorbs into my coffee and is super easy to drink! I've been enjoying it this way for several weeks now and have noticed that my hair and nails are getting stronger (which is important to me since I'm a new mom that's been experiencing postpartum hair loss). Thanks for creating a great product!"
                                    <h3>LINDSAY</h3>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} span={24}>

                        </Col>
                    </Row>
                    <Row gutter={[50, 15]}>
                        <Col md={12} span={24}>
                            <Row gutter={[25, 25]}>
                                <Col md={4} span={24}>
                                    <Avatar size={80} src="/resources/what-3.jpg" />
                                </Col>
                                <Col md={20} span={24}>
                                    <Rate
                                        value={5}
                                        style={{
                                            fontSize: 15,
                                            display: "block"
                                        }}
                                    />
                                    "Been taking Dr. Matthew’s digestive enzymes every time a have a big meal, and I can see the difference right away. No bloating, no gass, faster digestion."
                                    <h3>SILL</h3>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} span={24}>
                            <Row gutter={[25, 25]}>
                                <Col md={4} span={24}>
                                    <Avatar size={80} src="/resources/what-4.jpg" />
                                </Col>
                                <Col md={20} span={24}>
                                    <Rate
                                        value={5}
                                        style={{
                                            fontSize: 15,
                                            display: "block"
                                        }}
                                    />
                                    "I was having some “I’m getting into my 50’s and digesting food is becoming an issue” moments. Found these enzymes and I haven’t been happier. They just work."
                                    <h3>HARRY</h3>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} span={24}>

                        </Col>
                    </Row>
                </div>
            </div>
            <div className="Homepage-content">
                <div className="content">
                    <h2 ref={faq}>FREQUENTLY<br /> ASKED QUESTIONS</h2>
                    <p>Meet our happy clients and find why our nutritional products are the preferred choice.</p>
                    <Row gutter={25}>
                        <Col md={12} span={24}>
                            <Collapse bordered={false}>
                                <Panel header="Do I need to give you a credit card?" key="1">
                                    No, we do not ask you for a credit card at any time.
                                </Panel>
                                <Panel header="Do I have to pay for shipping?" key="2">
                                    No, you do not have to pay for anything, we will not charge you shipping and we will not ask you to enter any type of payment.
                                </Panel>
                                <Panel header="How long does it take to fill everything out on this site?" key="3">
                                    It typically takes users 1-3 minutes to fill out the necessary inputs to receive a free product.
                                </Panel>
                            </Collapse>
                        </Col>
                        <Col md={12} span={24} className="woman-small-image">
                            <span>
                                <img src="/resources/woman-small.png" alt="Woman small" />
                            </span>
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer style={{ marginBottom: 25 }} />
        </>
    )
}

export default Homepage;