import React, { useState } from "react";
import axios from 'axios';
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Row, Col, Modal, Form, Input, Button, message } from "antd";
import { CloseOutlined } from "@ant-design/icons"

import AppSteps from "./components/AppSteps";
import Manager from "./components/Manager";
import Homepage from "./components/Homepage";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import Step6 from "./components/Step6";
import Thanks from "./components/Thanks";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";

import "antd/dist/antd.css";
import "./App.scss";

if (process.env.NODE_ENV === "development") {
    axios.defaults.baseURL = "http://localhost:8000";
}

function Root() {
    return (
        <Router>
            <App/>
        </Router>
    )
}

function App() {
    const [form] = Form.useForm();
    const [displayHelpModal, setDisplayHelpModal] = useState(false)
    
    const handleDisplayHelpModal = () => {
        setDisplayHelpModal(true)
    }

    const hideHelpModal = () => {
        setDisplayHelpModal(false)
        form.resetFields();
    }

    const handleSendMessage = (fields) => {
        axios
            .post(`/api/message`, fields)
            .then(res => {
                message.success("Your message has been sent");
                form.resetFields();
                setDisplayHelpModal(false);
            });
    }

    return (
        <div className="App">
            <Route path='/:guid/' render={props =>
                <Modal
                    visible={displayHelpModal}
                    onCancel={hideHelpModal}
                    className="help-modal"
                    footer={null}
                    closeIcon={<CloseOutlined style={{ color: "#fff" }}/>}
                >
                    <h1>If you need help, please fill out this form</h1>
                    <Form onFinish={handleSendMessage} form={form}>
                        <Row gutter={25}>
                            <Col md={12} span={24}>
                                <Form.Item name="name" rules={[{required: true, message: "This field is required"}]}>
                                    <Input size="large" placeholder="Your name"/>
                                </Form.Item>
                            </Col>
                            <Col md={12} span={24}>
                                <Form.Item 
                                    name="email" 
                                    rules={[
                                        {required: true, message: "This field is required"}, 
                                        {type: "email", message: "Please enter correct E-mail address"}
                                    ]}
                                >
                                    <Input size="large" placeholder="E-mail"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={25}>
                            <Col span={24}>
                                <Form.Item name="message" rules={[{required: true, message: "This field is required"}]}>
                                    <Input.TextArea autoSize={{ minRows: 4, maxRows: 8 }} size="large" placeholder="Message" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={25}>
                            <Col span={24}>
                                <Button type="primary" htmlType="submit" size="large">
                                    SUBMIT
                                </Button>
                                <Link onClick={hideHelpModal} to={`/${props.match.params.guid}?showFAQ`}>
                                    See frequently asked questions
                                </Link>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                }>
            </Route>
            <Switch>
                <Route exact path="/terms-and-conditions" component={TermsAndConditions}/>
                <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                <Route exact path="/manager" component={Manager}/>
                <Route exact path="/" render={props => <Homepage {...props} onClickHelp={handleDisplayHelpModal}/>}/>
                <Route exact path="/:guid" render={props => <Homepage {...props} onClickHelp={handleDisplayHelpModal}/>}/>
                <Route path="/:guid/2" render={props => <Step2 {...props} onClickHelp={handleDisplayHelpModal}/>}/>
                <Route path="/:guid/3" render={props => <Step3 {...props} onClickHelp={handleDisplayHelpModal}/>}/>
                <Route path="/:guid/4" render={props => <Step4 {...props} onClickHelp={handleDisplayHelpModal}/>}/>
                <Route path="/:guid/5" render={props => <Step5 {...props} onClickHelp={handleDisplayHelpModal}/>}/>
                <Route path="/:guid/6" render={props => <Step6 {...props} onClickHelp={handleDisplayHelpModal}/>}/>
                <Route path="/:guid/summary" render={props => <Thanks {...props} onClickHelp={handleDisplayHelpModal}/>}/>
                <Route path="*">
                    <nav className="App-nav">
                        <Row style={{ paddingTop: 50, paddingBottom: 50 }}>
                            <Col span={16} offset={4}>
                                <AppSteps />
                            </Col>
                        </Row>
                    </nav>
                </Route>
            </Switch>
        </div>
    );
}

export default Root;