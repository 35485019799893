import React from 'react';

const TermsAndConditions = () => (
    <>
        <div className="info-pages">
            <img src="/resources/logo.png" alt="FreeDrFit logo" />
            <h1>GENERAL DISCLAIMER</h1>
            <p>Pursuant to U.S. State & Federal Laws the following is a statement of your legal rights.</p>
            <h2>Disclaimer & Legal Rights</h2>
            <h2>No Warranties</h2>
            <p>ALL WEB SITES, PRODUCTS AND SERVICES ARE PROVIDED, AS IS, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. OUR COMPANY DOES NOT WARRANT, GUARANTEE, OR MAKE ANY REPRESENTATIONS REGARDING THE USE, OR THE RESULTS OF THE USE, OF THE WEB SITES, PRODUCTS, SERVICES OR WRITTEN MATERIALS IN THE TERMS OF CORRECTNESS, ACCURACY, RELIABILITY, CURRENTNESS OR OTHERWISE. THE ENTIRE RISK AS TO THE RESULTS AND PERFORMANCE OF THE WEB SITES, PRODUCTS AND SERVICES ARE ASSUMED BY YOU. IF THE WEB SITES, PRODUCTS, SERVICES OR WRITTEN MATERIALS ARE DEFECTIVE, YOU, AND NOT OUR COMPANY, ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION. THIS IS THE ONLY WARRANT OF ANY KIND, EITHER EXPRESS OR IMPLIED, THAT IS MADE BY OUR COMPANY. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY OUR COMPANY SHALL CREATE A WARRANTY OR IN ANY WAY INCREASE THE SCOPE OF THIS WARRANTY, AND YOU MAY NOT RELY ON SUCH INFORMATION OR ADVICE TO DO SO.</p>
            <h2>Customer Remedy</h2>
            <p>Our company’s entire liability, and the purchaser’s exclusive remedy, shall be a refund of the price paid or replacement of our products, at our option. We limit replacement to thirty days. All remedies are limited to the United States. Some states do not allow the exclusion or limitation of liability, so the above limitations may not apply to you.</p>
            <h2>Limitation & Exclusion Of Liability</h2>
            <p>These warranties exclude all incidental or consequential damages. Our company, and its suppliers, will not be liable for any damages whatsoever, including without limitation, damages for loss of business profits, business interruption, loss of business information, or other pecuniary loss. Some states do not allow the exclusion or limitation of liability, so the above limitations may not apply to you.</p>
            <h2>Legal Forum, Choice Of Laws & Official Language</h2>
            <p>This offering is a contract between you the buyer and our business, the seller. The seller is located in Sheridan, Wyoming, U.S.A. and by doing business with us you agree that this offering is made from Sheridan, Wyoming, U.S.A. and shall be governed by the laws of the State of Wyoming and the U.S.A.. By electing to participate in this offer, you are entering into a contract.</p>
            <p>This Agreement shall be governed by and construed in accordance with the laws of the State of Wyoming, without regard to its conflict of laws rules. Any legal action arising out of this Agreement shall be litigated and enforced under the laws of the State of Wyoming. In addition, you agree to submit to the jurisdiction of the courts of the State of Wyoming, and that any legal action pursued by you shall be within the exclusive jurisdiction of the courts of Wyoming City in the State of Wyoming, USA.</p>
            <p>The terms constituting this offering are set forth in writing on this Web site. You hereby agree to submit to the jurisdiction of the State and Federal Courts located in Wyoming, Wyoming, U.S.A. to resolve any disputes or litigation hereunder. Whether or not you choose to print this offering, containing the terms and conditions as described herein, you agree that this contract constitutes a writing.</p>
            <p>This agreement is being written in English, which is to be the official language of the contract’s text and interpretation. If you do not agree with the above terms and conditions, you have the option to not participate in this offer.</p>
            <h2>Copyrights</h2>
            <p>This Web site and information contains copyrighted material, trademarks, and other proprietary information. You may not modify, publish, transmit, participate in the transfer or sale of, create derivative works of, on in any way exploit, in whole or in part, any Proprietary or other Material.</p>
            <h2>License</h2>
            <p>All images, text, contents, products and scripts are licensed and never sold, unless otherwise stated. Reproduction is prohibited. You may not use, copy, emulate, clone, rent, lease, sell, modify, decompile, disassemble, otherwise reverse engineer, or transfer the licensed program or product, or any subset of the licensed program or product, except as provided for in this agreement or expressly in writing. Any such unauthorized use shall result in immediate and automatic termination of this license and may result in criminal and/or civil prosecution. Our company reserves all rights not expressly granted here.</p>
            <p>Last Update: June 1st, 2021</p>
        </div>
    </>);

export default TermsAndConditions;