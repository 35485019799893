import React from 'react';
import { Button, Col, Row, Steps } from 'antd';
import Footer from './Footer';

const { Step } = Steps;

const AppSteps = ({ step, children, onClickHelp }) => (
    <Row>
        <Col lg={7} span={0} className="AppSteps-woman-image">
            <img src="/resources/logo.png" alt="FreeDrFit logo" />
        </Col>
        <Col lg={17} span={24} className="AppSteps-content">
            <div>
                <div className="steps">
                    <div className="container content" style={{ padding: 0 }}>
                        <Steps className="default" current={step || 1} labelPlacement="vertical">
                            <Step />
                            <Step />
                            <Step />
                            <Step />
                            <Step />
                        </Steps>
                        <div className="mobile">
                            {step === 1 && <div><span>2</span></div>}
                            {step === 2 && <div><span>3</span></div>}
                            {step === 3 && <div><span>4</span></div>}
                            {step === 4 && <div><span>5</span></div>}
                            {step === 5 && <div><span>6</span></div>}
                        </div>
                        <Button type="link" onClick={onClickHelp}>
                            Help
                        </Button>
                    </div>
                </div>
                <div className="content" style={{ paddingBottom: 0 }}>
                    {children}
                </div>
            </div>
            <div>
                <Footer/>
            </div>
        </Col>
    </Row>
    
);

export default AppSteps;