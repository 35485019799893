import React from 'react'
import { Col, Row } from 'antd'

export default function HowToFind() {
    return (
        <div className="HowToFind-container">
            <h2>How can I find my Order ID?</h2>
            <Row>
                <Col md={15} span={24}>
                    <h3>You can find your order ID in the following places:</h3>
                    <p>Your Amazon account <a href="https://www.amazon.com/ap/signin?_encoding=UTF8&accountStatusPolicy=P1&openid.assoc_handle=usflex&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.mode=checkid_setup&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&openid.ns.pape=http%3A%2F%2Fspecs.openid.net%2Fextensions%2Fpape%2F1.0&openid.pape.max_auth_age=0&openid.return_to=https%3A%2F%2Fwww.amazon.com%2Fgp%2Fcss%2Forder-history%3Fie%3DUTF8%26%252AVersion%252A%3D1%26%252Aentries%252A%3D0&pageId=webcs-yourorder&showRmrMe=1" style={{ color: "#78D0E4" }} target="_blank" rel="noopener noreferrer">order history</a>.</p>
                    <p>Your order confirmation email from Amazon.</p>
                    <p>The receipt you received with your order.</p>
                </Col>
                <Col offset={1} md={8} span={24}>
                    <img src={`/resources/amazon.png`} alt="How to find order id?" />
                </Col>
            </Row>
        </div>
    )
}
