import axios from 'axios';
import { Button, Col, Input, Row, Form } from 'antd'
import React, { useState, useEffect } from 'react';
import AppSteps from './AppSteps'
import Rate from './Stars'
import Product from './Product';
import products from '../products.json';


export default function Step3(props) {
    const [rate, setRate] = useState(0);
    const [opinion, setOpinion] = useState('');
    const [validateStatus, setValidateStatus] = useState('');
    const [sku, setSku] = useState('');
    const [skipReview, setSkipReview] = useState(false);

    const validateInput = (value) => {
        setValidateStatus(value && value.length >= 50 ? "success" : "error")
        setOpinion(value)
    }

    const submit = () => {
        axios
        .post(`/api/orders/${props.match.params.guid}/3`, {
            rate,
            review: opinion
        })
        .then(res => {
            if(rate >= 4 && !skipReview) {
                props.history.push(`/${props.match.params.guid}/4`);
            } else {
                props.history.push(`/${props.match.params.guid}/5`);
            }
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);

         axios
            .get(`/api/orders/${props.match.params.guid}`)
            .then(response => response.data)
            .then(data => {
                if(!data) 
                    return;

                if(!data.order_no) 
                    return;

                setSku(data.sku);
                setSkipReview(data.skipReview);
            })
    }, [props.match.params.guid]);

    var selecteProduct = '';

    if(sku && products[sku]) {
        selecteProduct = <>
            <Product sku={sku} isSelected={true} />
            <h2 style={{ fontSize: 18 }}>{products[sku].title}</h2>
            <p>{products[sku].description}</p>
        </>
    }

    return (
        <AppSteps step={2} onClickHelp={props.onClickHelp}>
            <Row gutter={25} className="Step3-container">
                <Col md={8} span={24}>{selecteProduct}</Col>
                <Col md={15} span={24}>
                    <h2>Are you satisfied with our product?</h2>
                    <Rate rate={rate} onChange={rate => setRate(rate)}/>

                    <h2 style={{ marginTop: 25 }}>Describe how our product is working <br/>for you</h2>
                    
                    <Form>
                        <Form.Item hasFeedback validateStatus={validateStatus}>
                            <Input.TextArea 
                                autoSize={{ minRows: 4 }} 
                                placeholder="Write your opinion"
                                value={opinion}
                                onChange={e => validateInput(e.target.value)}
                            />
                            <p className="opinion-holder">
                                {validateStatus === "error" &&
                                    `Please complete at least 50 characters (${opinion.length}/50)`
                                }
                            </p>
                        </Form.Item>

                        <Button
                            onClick={submit} 
                            style={{ padding: "0px 50px" }} 
                            size="large" 
                            type="primary"
                            disabled={validateStatus !== "success" || rate === 0}
                        >
                            NEXT
                        </Button>
                    </Form>
                </Col>
            </Row>
        </AppSteps>
    )
}
