import React, { useState } from 'react'

export default function Rate({ rate, onChange }) {
    const [hoverRate, setHoverRate] = useState(null)

    const shouldBeFilled = (position) => {
        if(hoverRate)
            return hoverRate >= position;

        return rate >= position;
    }

    return (
        <div className="Stars">
            <img src={`/resources/${shouldBeFilled(1) ? "filled" : "empty"}-star.png`} onMouseEnter={() => setHoverRate(1)} onMouseLeave={() => setHoverRate(null)} onClick={() => onChange(1)} alt="star-rating-1" />
            <img src={`/resources/${shouldBeFilled(2) ? "filled" : "empty"}-star.png`} onMouseEnter={() => setHoverRate(2)} onMouseLeave={() => setHoverRate(null)} onClick={() => onChange(2)} alt="star-rating-2"/>
            <img src={`/resources/${shouldBeFilled(3) ? "filled" : "empty"}-star.png`} onMouseEnter={() => setHoverRate(3)} onMouseLeave={() => setHoverRate(null)} onClick={() => onChange(3)} alt="star-rating-3"/>
            <img src={`/resources/${shouldBeFilled(4) ? "filled" : "empty"}-star.png`} onMouseEnter={() => setHoverRate(4)} onMouseLeave={() => setHoverRate(null)} onClick={() => onChange(4)} alt="star-rating-4"/>
            <img src={`/resources/${shouldBeFilled(5) ? "filled" : "empty"}-star.png`} onMouseEnter={() => setHoverRate(5)} onMouseLeave={() => setHoverRate(null)} onClick={() => onChange(5)} alt="star-rating-5"/>
        </div>
    )
}
