import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MaskedInput from 'antd-mask-input'
import { Alert, Button, Form } from 'antd';

import AppSteps from './AppSteps';
import HowToFind from './HowToFind';

const Step2 = (props) => {
    const [order, setOrder] = useState('');
    const [validateStatus, setValidateStatus] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        setValidateStatus('')
        setOrder('')
    }, [])

    const validateOrderID = (orderId) => {
        setOrder(orderId);

        if (/^[0-9]{3}-[0-9]{7}-[0-9]{7}$/.test(orderId)) {
            setValidateStatus('validating');
            simulateVeryfying(orderId);
        } else {
            setValidateStatus('');
        }
    }

    const simulateVeryfying = (orderId) => {
        axios
            .post(`/api/orders/${props.match.params.guid}/2`, {
                orderId
            })
            .then(response => response.data)
            .then(result => {
                setValidateStatus(result.status === 'valid' ? 'success' : 'error');

                if(result.status === 'used') {
                    setError('The order # you submitted was already used.');
                } else if (result.status === 'invalid') {
                    setError("Order # was not found or is ineligible for a free gift.");
                } else if (result.status === 'try_later') {
                    setError('Not enough time has passed to qualify for your free product. You must wait until it has been 15 days since your original purchase.');
                } else {
                    setError('');
                }
            })
            .catch(() => {
                setValidateStatus('error');
                setError('Can\'t validate your order #.');
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        
        axios
             .get(`/api/orders/${props.match.params.guid}`)
             .then(response => response.data)
             .then(data => {
                 if(!data) return;
                 if(!data.order_no) return;

                 setOrder(data.order_no);
                 setValidateStatus('success');
             })
    }, [props.match.params.guid]);

    const submit = () => {
        props.history.push(`/${props.match.params.guid}/3`);
    }

    return (
        <>
            <AppSteps step={1} onClickHelp={props.onClickHelp}>
                <div className="Step-container">
                    <h1>Help us find your order</h1>
                    <h2 className="additional-info">Enter Your Order ID Number</h2>
                    <Form layout="vertical" className="order">
                        <Form.Item style={{ marginBottom: "10px" }} hasFeedback validateStatus={validateStatus}>
                            <MaskedInput 
                                size="large" 
                                placeholder="Enter your order" 
                                value={order} 
                                onChange={e => validateOrderID(e.target.value)} 
                                mask={"111-1111111-1111111"}
                            />
                        </Form.Item>
                        <Button 
                            type="primary" 
                            size="large" 
                            onClick={submit}
                            disabled={validateStatus !== "success"}
                            block
                        >
                            Next
                        </Button>
                    </Form>
                    Example # 111-1234567-1234567
                    <div className="error-wrapper">
                        {validateStatus === 'error' &&
                            <Alert type="error" className="error" message={error} closable={true}/>
                        }
                    </div>
                    <HowToFind />
                </div>
            </AppSteps>
        </>
    );
}

export default Step2;