import { Button, Col, Input, Row, message } from 'antd'
import axios from 'axios';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import AppSteps from './AppSteps'

export default function Step4(props) {
    const textAreaRef = useRef(null);

    const [review, setReview] = useState('');
    const [asin, setAsin] = useState('');
    
    useEffect(() => {
        axios
            .get(`/api/orders/${props.match.params.guid}`)
            .then(res => res.data)
            .then(order => {
                setReview(order.review);
                setAsin(order.asin);
            })
    }, [props.match.params.guid])

    const copyToClipBoard = (e) => {
        navigator.clipboard.writeText(review);
        message.success("Review has been copied")
    }

    const amazonLinkClicked = useCallback(() => {
        axios.post(`/api/orders/${props.match.params.guid}/amazon-clicked`);
    }, [props.match.params.guid])

    return (
        <AppSteps step={2} onClickHelp={props.onClickHelp}>
            <Row gutter={25} className="Step4-container">
                <h2>We are glad you are enjoying our Dr. Matthew product</h2>
                <p>Please take a moment to share your experience with others. While it’s not mandatory, your feedback can help others just like you find our great products!</p>
                <Row style={{ marginTop: 10, width: "100%" }} gutter={[50, 25]}>
                    <Col md={12} span={24}>
                        <h3>Step 1 - Copy text below:</h3>
                        <Input.TextArea
                            value={review}
                            onChange={e => setReview(e.target.value)}
                            autoSize={{
                                minRows: 4
                            }}
                            ref={textAreaRef}
                        />
                        <Button
                            type="primary"
                            size="large"
                            style={{ 
                                marginTop: 25,
                                fontSize: 13,
                                width: 150
                            }}
                            onClick={copyToClipBoard}
                        >
                            COPY REVIEW
                        </Button>
                    </Col>
                    <Col md={12} span={24}>
                        <h3>Step 2 - Paste on Amazon:</h3>
                        <div className="amazon-container">
                            <img src="/resources/Amazon-logo.png" alt="Amazon" />
                        </div>
                        <a 
                            href={`https://www.amazon.com/review/create-review?asin=${asin}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={amazonLinkClicked}
                        >
                            <Button
                                size="large"
                                style={{ 
                                    marginTop: 25,
                                    fontSize: 13,
                                    width: 150
                                }}
                                className="btn-outlined"
                            >
                                CLICK HERE
                            </Button>
                        </a>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10, width: "100%" }} gutter={[50, 25]}>
                    <Col md={12} span={24}>
                        <h3>Step 3</h3>
                        <p>Once you have shared your experience, click next to confirm your shipping address and receive your free product!</p>
                        <Button 
                            className="btn-outlined"
                            size="large"
                            style={{ width: 150 }}
                            onClick={() => props.history.push(`/${props.match.params.guid}/5`)}
                        >
                            NEXT
                        </Button>
                    </Col>
                </Row>
            </Row>
        </AppSteps>
    )
}
